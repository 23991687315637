import IMask from 'imask';
import {
  FunderMemberAssociation,
  FunderServiceLevel
} from '../enums/funder/funder-enums';
import { OrgStatus } from '../enums/grantee-status.enum';
import { ApplicationStatus } from '../enums/applicaiton-status.enum';
import { ProposalStatus } from '../enums/proposal-status.enum';
import { ProposalUrgentNeed } from '../enums/proposal-urgent-need';
import { UserStatus } from '../enums/user-status';
import { IBloatedFunder, IMember } from '../interfaces/IFunder';
import { IBloatedGrantee } from '../interfaces/IGrantee';
import { Scope } from '../enums/scope.enum';
import { CommitmentReason } from '../enums/commitment.enum';
import { Format } from '../interfaces/ICommitment.interfaces';
import { TaxStatusId, TaxStatusObj } from '../enums/tax-status-id.enum';
import { RFPStatus, RFPStatusLabel } from '../enums/rfp-status';
import { IRfp } from '../interfaces/IRfp.interfaces';
import { IProfile } from '../interfaces/IViewProfile.interface';
import { createTheme } from '@mui/material';
import { states } from '../../common/data/data';
import { IEventCommitment } from '../../funder/components/events/EventsCommitmentList';
import { IUser } from '../interfaces/IUser.interface';
import { BipocLed } from '../enums/bipoc.enum';
import { SurveyQuestionType } from '../enums/survey.enum';
import { GrantCycleScoringStatus } from '../enums/scoring.enum';
import { TStatus } from '../../funder/components/analyze/components/CheckInStatus/interfaces';
import { statusMap } from '../../funder/components/analyze/components/CheckInStatus/StatusMap';

interface IKeyValue {
  key: string;
  value: string;
}

/**
 *
 * @param val Any ENUM
 * @returns Any array of key value pairs matched to your enum { Test } = { key: "Test", value: 0 }
 */

export const keyValue = (val: Record<any, any>): IKeyValue[] => {
  const items = Object.entries(val);
  return items
    .map((e) => ({ key: e[0], value: e[1] }))
    .filter(({ key }) => isNaN(Number(key)));
};

export const maskedValue = (mask: string, value: string): string => {
  let maskedValue = '';
  if (value.length > 0) {
    let masked = IMask.createMask({
      mask
    });
    maskedValue = masked.resolve(value).toString();
  }
  return maskedValue;
};

export const formatMoney = (value: string | null): string => {
  if (!value || value.length === 0) return '$0.00';

  let masked = IMask.createMask({
    mask: '$num',
    blocks: {
      num: {
        mask: Number,
        thousandsSeparator: ','
      }
    }
  });

  return masked.resolve(value).toString();
};

export function formatCurrencyInput(
  value: string | null,
  showDollarSign = true
): string {
  let maskedValue = '';
  if (value && value.length > 0) {
    let masked = IMask.createMask({
      mask: `${showDollarSign ? '$' : ''}num`,
      blocks: {
        num: {
          mask: Number,
          thousandsSeparator: ',',
          signed: false,
          scale: 2,
          padFractionalZeros: false,
          normalizeZeros: false,
          radix: '.',
          mapToRadix: ['.'],
          min: 0,
          max: 1000000000
        }
      }
    });
    maskedValue = masked.resolve(value).toString();
  }
  return maskedValue;
}

export const maxCharacters = (value: string, length: number): string => {
  if (value?.length > length) {
    return value.substring(0, length) + '...';
  }
  return value;
};

// Uses Intl to format dollars
export const dollarFormatter = (
  dollarAmount: any,
  maximumFractionDigits: number = 2
): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits
  }).format(dollarAmount);
  return formatter;
};

export const getTaxStatus = (status: TaxStatusId) => {
  return TaxStatusObj[status];
};

/**
 * @param FunderMember as IMember
 * @returns parent Funder's network configuration
 */

const networkConfigObj = {
  [FunderMemberAssociation.UMBRELLA_MEMBER]: 'Umbrella',
  [FunderMemberAssociation.COLLABORATIVE_MEMBER]: 'Collaborative'
} as Record<FunderMemberAssociation, string>;
export const CheckFunderConfiguration = (FunderMember: IMember): string => {
  return FunderMember ? networkConfigObj[FunderMember.member_association] : '';
};

// This is fix a bug where an active proposal wouldn't have all fields rendered
// To address this, this function will force assign a value to a JSX component
// If the user is an Admin, or the proposal is anything other than active, this returns void
export const activeRender = (
  proposalStatus: number,
  defaultValue: string | number,
  fieldOutput: string | string[] | number | number[] | undefined,
  isAdmin: boolean
) => {
  const inputProps = {
    value: fieldOutput
  };
  const defaultProps = {
    value: defaultValue
  };
  if (
    isAdmin ||
    (proposalStatus !== ProposalStatus.Active &&
      proposalStatus !== ProposalStatus.Archived)
  ) {
    return;
  } else if (!fieldOutput) {
    return defaultProps;
  } else {
    return inputProps;
  }
};

// Checks a contact array for a primary user value
// Takes two arguments, the first being a grantee object
// The second is a bool value to force the function to return false
export const checkForPrimary = (
  grantee: IBloatedGrantee,
  forceFalse: boolean
): boolean => {
  if (forceFalse) {
    return false;
  }
  const hasPrimary = (primary: boolean) => primary === true;
  return grantee?.granteeContacts?.some((contact) =>
    hasPrimary(!!contact?.primary)
  )
    ? true
    : false;
};

/**
 *
 * @param enumList Enum to convert. Needs to be enums with only numbers as values
 * @returns Array of objects with value and name attributes mapping to the enum
 */
export const enumToList = (
  enumList: any
): { name: string; value: number }[] => {
  return Object.entries(enumList)
    .filter(([_, key]) => isNaN(parseInt(key as string, 10)))
    .map(([val, key]) => {
      return {
        value: Number(val),
        name: key as string
      };
    });
};

/**
 *
 * @param scope Enum (proposal.scope) to convert, should be an integer value only.
 * @returns String matching enum value.
 */

type ScopeDef = {
  [key in Scope]: string;
};

export const scopeObj: ScopeDef = {
  [Scope.NATIONAL]: 'National',
  [Scope.STATE]: 'State',
  [Scope.REGIONAL]: 'Regional',
  [Scope.LOCAL]: 'Local',
  [Scope.NONE]: 'None'
};

export const getScope = (scope: Scope) => {
  return scopeObj[scope];
};

/**
 *
 * @param CommitmentDef Enum (grant_type) to convert, should be an integer value only.
 * @returns String matching enum value.
 */

type CommitmentDef = {
  [key in CommitmentReason]: string;
};

export const supportObj: CommitmentDef = {
  [CommitmentReason.GENERAL]: 'General Operating Grant',
  [CommitmentReason.NONE]: 'None',
  [CommitmentReason.OTHER]: 'Other',
  [CommitmentReason.PROGRAM_SUPPORT]: 'Program or Project Support Grant'
};

export const getCommitmentSupport = (commitment: CommitmentReason) => {
  return supportObj[commitment];
};

// Remove all non-unique values from Array
export const getUniques = <T = any>(arr: T[]): T[] => {
  return Array.from(new Set(arr)) as T[];
};

// TODO - see urgent need object literal. Refactor other switch cases to follow a similar pattern. Will help performance, and maintainability / readability.
/**
 *
 * @param ProposalStatus Enum (proposal.status) to convert, should be an integer value only.
 * @returns String matching enum value.
 */
export const getProposalStatus = (status: ProposalStatus | undefined) => {
  switch (status) {
    case ProposalStatus.Active:
      return 'Active';
    case ProposalStatus.Archived:
      return 'Archived';
    case ProposalStatus.Draft:
      return 'Draft';
    case ProposalStatus.Active_Draft:
      return 'Active Proposal with Draft Application';
    case ProposalStatus.Deleted:
      return 'Deleted';
    default:
      return undefined;
  }
};

/**
 * @param status UserStatus enum to convert, should be an integer value only.
 * @returns String matching enum value.
 */

const userStatusObj = {
  [UserStatus.ACTIVE]: 'Active',
  [UserStatus.INACTIVE]: 'Inactive',
  [UserStatus.UNREGISTERED]: 'Pending',
  [UserStatus.SUSPENDED]: 'Suspended',
  [UserStatus.DELETED]: 'Deleted',
  [UserStatus.INVITED]: 'Invited'
};

export const getUserStatus = (status: UserStatus) => {
  return userStatusObj[status];
};

export const getApplicationStatus = (status: ApplicationStatus | undefined) => {
  switch (status) {
    case ApplicationStatus.AWARDED:
      return 'Awarded';
    case ApplicationStatus.DRAFT:
      return 'Draft';
    case ApplicationStatus.PENDING:
      return 'Submitted';
    case ApplicationStatus.REJECTED:
      return 'Rejected';
    default:
      return 'Draft';
  }
};

export const getApplicationStatusForApplicant = (
  status: ApplicationStatus | undefined
) => {
  switch (status) {
    case ApplicationStatus.DRAFT:
      return 'Draft';
    case ApplicationStatus.PENDING:
    case ApplicationStatus.AWARDED:
    case ApplicationStatus.REJECTED:
      return 'Submitted';
    default:
      return 'Draft';
  }
};

export const getInvitationStatusFromApplication = (
  status: ApplicationStatus | undefined
) => {
  const submittedStatus = [
    ApplicationStatus.PENDING,
    ApplicationStatus.AWARDED,
    ApplicationStatus.REJECTED
  ];
  if (status === undefined) return 'Not Started';
  if (status === ApplicationStatus.DRAFT) return 'Draft';
  if (submittedStatus.includes(status)) return 'Submitted';
  return 'Draft';
};

export const getApplicationFundingStatus = (status: ApplicationStatus) => {
  switch (status) {
    case ApplicationStatus.AWARDED:
      return 'Committed';
    case ApplicationStatus.REJECTED:
      return 'Declined';
    default:
      return 'Uncommitted';
  }
};

export const getGrantCycleScoringStatus = (status: GrantCycleScoringStatus) => {
  switch (status) {
    case GrantCycleScoringStatus.IN_REVIEW:
      return 'In Review';
    case GrantCycleScoringStatus.INACTIVE:
      return 'Inactive';
    default:
      return 'All';
  }
};

/**
 * @param status Proposal Urgent Need enum to convert, should be an integer value only.
 * @returns String matching enum value.
 */

const urgentNeedObj = {
  [ProposalUrgentNeed.COVID_19]: 'Covid-19',
  [ProposalUrgentNeed.ABORTION_RIGHTS]: 'Abortion Rights',
  [ProposalUrgentNeed.REFUGEE_RESETTLEMENT]: 'Refugee Resettlement',
  [ProposalUrgentNeed.NONE]: 'None'
};
export const getUrgentNeed = (urgentNeed: ProposalUrgentNeed) => {
  return urgentNeedObj[urgentNeed];
};

const funderServiceLevelObj = {
  [FunderServiceLevel.Enterprise]: 'Enterprise',
  [FunderServiceLevel.Standard]: 'Standard',
  [FunderServiceLevel.FullService]: 'Full Service',
  [FunderServiceLevel.Institution]: 'Institution',
  [FunderServiceLevel.Individual]: 'Individual'
};

export const getFunderServiceLevel = (serviceLevel: FunderServiceLevel) => {
  return funderServiceLevelObj[serviceLevel];
};

const statusObj = {
  [OrgStatus.ACTIVE]: 'Active',
  [OrgStatus.ARCHIVED]: 'Archived'
};

export const getStatus = (status: OrgStatus) => {
  return statusObj[status];
};
/**
 *
 * @param string any string. removes white spaces / special characters from string.
 * @returns String value
 */
export function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const memberObj = {
  [FunderMemberAssociation.COLLABORATIVE_LEAD]: 'Collaborative Lead',
  [FunderMemberAssociation.COLLABORATIVE_MEMBER]: 'Collaborative Member',
  [FunderMemberAssociation.UMBRELLA_LEAD]: 'Umbrella Lead',
  [FunderMemberAssociation.UMBRELLA_MEMBER]: 'Umbrella Lead'
};
export function getFunderMemberType(
  memberAssociation: FunderMemberAssociation
) {
  return memberObj[memberAssociation];
}
export const paymentType = {
  [Format.CHECK]: 'Check',
  [Format.ONLINE]: 'Online',
  [Format.STOCK]: 'Stock',
  [Format.ACH]: 'ACH',
  [Format.WIRE]: 'Wire',
  [Format.OTHER]: 'Other'
};

export const getPaymentType = (payment: Format) => {
  return paymentType[payment];
};

export const commitmentReasonType = {
  [CommitmentReason.NONE]: 'None',
  [CommitmentReason.GENERAL]: 'General Operating Grant',
  [CommitmentReason.PROGRAM_SUPPORT]: 'Program or Project Support Grant',
  [CommitmentReason.OTHER]: 'Other'
};

export const getCommitmentReasontype = (commitment: CommitmentReason) => {
  return commitmentReasonType[commitment];
};

export const rfpStatusObj = {
  [RFPStatus.APPROVED]: 'Approved',
  [RFPStatus.SUBMITTED]: 'Submitted',
  [RFPStatus.DRAFT]: 'Draft / In-Progress'
};

export const getRFPStatus = (rfpStatus: RFPStatus) => {
  return rfpStatusObj[rfpStatus];
};

// export function composeCycleQuestions(formData: any, survey: any | undefined) {
//   let cycleQuestions: any[] = [];
//   console.log('compose cycle questions form data', formData, survey)
//   if (formData.question1 || (!formData.question1 && survey?.[0])) cycleQuestions.push({
//     question_id: survey?.[0]?.id || null,
//     description: formData.question1,
//     requireQuestion: formData.require_question1 || "false",
//     type: parseInt(formData.question_type1 || 0),
//     payload: parseInt(formData.question_type1 || 0) === SurveyQuestionType.MULTIPLE ? JSON.stringify(formData.question1_options) : null
//   })
//   if (formData.question2 || (!formData.question2 && survey?.[1])) cycleQuestions.push({
//     question_id: survey?.[1]?.id || null,
//     description: formData.question2,
//     requireQuestion: formData.require_question2 || "false",
//     type: parseInt(formData.question_type2 || 0),
//     payload: parseInt(formData.question_type2 || 0) === SurveyQuestionType.MULTIPLE ? JSON.stringify(formData.question2_options) : null
//   })
//   if (formData.question3 || (!formData.question3 && survey?.[2])) cycleQuestions.push({
//     question_id: survey?.[2]?.id || null,
//     description: formData.question3,
//     requireQuestion: formData.require_question3 || "false",
//     type: parseInt(formData.question_type3 || 0),
//     payload: parseInt(formData.question_type3 || 0) === SurveyQuestionType.MULTIPLE ? JSON.stringify(formData.question3_options) : null
//   })
//   console.log('compose cycle questions', cycleQuestions)
//   return cycleQuestions;
// }

export function composeCycleQuestions(formData: any, survey: any | undefined) {
  let cycleQuestions: any[] = [];
  console.log('compose cycle questions form data', formData, survey);
  const shouldAddQuestion = (id: number) =>
    !formData[`removeQuestion${id}`] &&
    (survey?.[id - 1] ||
      (formData?.[`question${id}`] !== null &&
        formData?.[`question${id}`] !== undefined));

  const getDescription = (id: number) =>
    formData[`question${id}`] !== null &&
    formData[`question${id}`] !== undefined
      ? formData[`question${id}`]
      : survey?.[id - 1]?.description || '';

  const getPayload = (id: number) => {
    const payload =
      formData[`question${id}_options`] || survey?.[id - 1]?.payload;
    return parseInt(
      formData[`question_type${id}`] || survey?.[id - 1]?.type || 0
    ) === SurveyQuestionType.MULTIPLE && Array.isArray(payload)
      ? JSON.stringify(payload)
      : null;
  };

  if (shouldAddQuestion(1))
    cycleQuestions.push({
      question_id: survey?.[0]?.id || null,
      description: getDescription(1),
      requireQuestion:
        formData.require_question1 || survey?.[0]?.is_required || 'false',
      type: parseInt(formData.question_type1 || survey?.[0]?.type || 0),
      payload: getPayload(1)
    });
  if (shouldAddQuestion(2))
    cycleQuestions.push({
      question_id: survey?.[1]?.id || null,
      description: getDescription(2),
      requireQuestion:
        formData.require_question2 || survey?.[1]?.is_required || 'false',
      type: parseInt(formData.question_type2 || survey?.[1]?.type || 0),
      payload: getPayload(2)
    });
  if (shouldAddQuestion(3))
    cycleQuestions.push({
      question_id: survey?.[2]?.id || null,
      description: getDescription(3),
      requireQuestion:
        formData.require_question3 || survey?.[2]?.is_required || 'false',
      type: parseInt(formData.question_type3 || survey?.[2]?.type || 0),
      payload: getPayload(3)
    });
  // console.log('compose cycle questions', cycleQuestions)
  return cycleQuestions;
}

export const composeTaxStatus = (formData: any, cycle: IRfp) => {
  let taxStatus: any[] = [];
  const findTaxStatus = (taxStatus: any) =>
    cycle?.rfpTaxStatuses?.find((status) => status.tax_status_id === taxStatus);
  formData?.map((status: any, index: any) => {
    if (status || (!status && findTaxStatus(index))) {
      taxStatus.push({
        id: findTaxStatus(index)?.id || null,
        rfp_id: cycle.id,
        tax_status_id: index,
        ...(index === TaxStatusId.Other && { detail: status.detail || '' }),
        remove: status || status === undefined ? false : true
      });
    }
  });
  return taxStatus;
};

export const checkRfpStatus = (rfp: IRfp): RFPStatusLabel => {
  const today = new Date();

  const parseDate = (dateStr: string | undefined) => {
    return dateStr ? new Date(dateStr) : null;
  };

  if (rfp.deleted_at) {
    return RFPStatusLabel.DELETED;
  }

  if (rfp.status === RFPStatus.DRAFT) {
    return RFPStatusLabel.DRAFT;
  }

  const deadlineDate = parseDate(rfp.deadline_date);
  if (deadlineDate && today > deadlineDate) {
    return RFPStatusLabel.CLOSED;
  }

  const announcementDate = parseDate(rfp.announcement_date);
  const rfpStatus = getRFPStatus(rfp.status);
  if (
    rfpStatus === RFPStatusLabel.APPROVED &&
    announcementDate &&
    today >= announcementDate
  ) {
    return RFPStatusLabel.ACTIVE;
  }

  return rfpStatus as RFPStatusLabel;
};

export const requiredErrorMessage = 'This field is required';

export const filterComponentCheck = (entity: IProfile) => {
  if (!!entity) {
    if (entity.bipoc_led === BipocLed.YES) {
      return true;
    }
    if (entity.focuses?.length > 0) {
      return true;
    }
    if (entity.granteeStrategies && entity.granteeStrategies?.length > 0) {
      return true;
    }
    if (entity.granteeCommunities && entity.granteeCommunities?.length > 0) {
      return true;
    }
    if (!!entity.scope && entity.scope !== 5) {
      return true;
    }
    if (entity.granteeStates && entity.granteeStates.length > 0) {
      return true;
    }
    return false;
  }
};

export const determineDetails = (funder: IBloatedFunder) => {
  if (
    (funder.fundPriorities && funder.fundPriorities.length > 0) ||
    (funder.grantCategories && funder.grantCategories.length > 0) ||
    (funder.fundCategories && funder.fundCategories.length > 0) ||
    funder.annual_grant_total ||
    (funder.decisionStructures && funder.decisionStructures.length > 0) ||
    (funder.typeOfFunds && funder.typeOfFunds.length > 0) ||
    funder?.average_grant_size
  )
    return true;
  return false;
};

export const renderSelections = (
  selectedIds: string[],
  selectableObjects: any[]
) => {
  const selectionString = selectableObjects
    .map((selection) => {
      if (selectedIds.includes(selection.id)) return selection.name;
      return null;
    })
    .filter((focus) => focus !== null)
    .join(', ');
  return selectionString;
};
export const renderScopes = (
  selectedIds: string[],
  selectableObjects: any[]
) => {
  const selectionString = selectableObjects
    .map((selection) => {
      if (selectedIds.includes(selection.value)) return selection.name;
      return null;
    })
    .filter((focus) => focus !== null)
    .join(', ');
  return selectionString;
};

export const formatStates = () => {
  return states.map((state) => {
    if (state['value'] !== 'id') {
      return Object.defineProperty(
        state,
        'id',
        Object.getOwnPropertyDescriptor(state, 'value')!
      );
    }
  });
};

export const scrollbarOverride = {
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
    width: '0.2em'
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
    background: '#f1f1f1'
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
    backgroundColor: '#888'
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
    background: '#555'
  }
};

export const theme = createTheme();

export const sortAToZ = (commitments: IEventCommitment[]) => {
  const sortedArray = [...commitments].sort((a, b) => {
    if (a.grantee.name.toUpperCase() < b.grantee.name.toUpperCase()) {
      return -1;
    }
    if (a.grantee.name.toUpperCase() > b.grantee.name.toUpperCase()) {
      return 1;
    }
    return 0;
  });
  return sortedArray;
};

export const sortHighToLow = (commitments: IEventCommitment[]) => {
  const sortedArray = [...commitments].sort((a, b) => b.amount - a.amount);
  return sortedArray;
};

export const sortComittedDate = (commitments: IEventCommitment[]) => {
  const sortedArray = [...commitments].sort((a, b) => {
    return new Date(b.committed).valueOf() - new Date(a.committed).valueOf();
  });
  return sortedArray;
};

export const getProfileRedirectUri = (
  id: number,
  isGrantee: boolean,
  isAdmin: boolean
) => {
  let redirectUri;

  if (isGrantee && isAdmin) {
    redirectUri = `/admin/manage/public-profile-grantee/${id}`;
  } else if (!isGrantee && isAdmin) {
    redirectUri = `/admin/manage/public-profile-funder/${id}`;
  } else if (isGrantee) {
    redirectUri = `/f/grantee-profile/${id}`;
  } else {
    redirectUri = `/f/funder-profile/${id}`;
  }

  return redirectUri;
};

export const splitArray = (data: any[], limit: number) => {
  const a = data.slice(0, limit);
  const b = data.slice(limit);
  return [a, b];
};

export const addPrefixToObjectProps = (
  obj: Record<string, any>,
  prefix: string
) => {
  let newObject: Record<string, any> = {};
  Object.entries(obj).forEach(([key, value]) => {
    const index: string = `${prefix}_${key}`;
    newObject[index] = value;
  });
  return newObject;
};

export const formatUserName = (user: IUser) =>
  `${user.first_name} ${user.last_name}`;

// TODO: This shouldn't be based on category, as an admin can change the attachment category and this would break
export const renderAttachment = (resource: any, attachments: any[]) => {
  const matchedAttachment = attachments.find(
    (attachment) =>
      attachment.rfp_resource_id === resource.id ||
      attachment.ProposalResource?.rfp_resource_id === resource.id
  );
  return matchedAttachment;
};

export const checkGranteeProfile = (
  grantee: IBloatedGrantee | null
): boolean => {
  const reqGranteeModel = {
    name: grantee?.name || null,
    established: grantee?.established || null,
    country: grantee?.country_alpha_2 || null,
    street: grantee?.location?.address1 || null,
    stateOrTerritory: grantee?.location?.state || null,
    city: grantee?.location?.city || null,
    mission: grantee?.mission || null,
    orgDescription: grantee?.description || null,
    teamDescription: grantee?.team_description || null,
    focusAreas: (grantee?.focuses && grantee?.focuses.length > 0) || null,
    bipocLed: grantee?.bipoc_led !== undefined || null,
    orgContactFirstName:
      grantee?.granteeContacts?.[0]?.contact?.first_name || null,
    orgContactLastName:
      grantee?.granteeContacts?.[0]?.contact?.last_name || null,
    orgContact: grantee?.granteeContacts?.[0]?.contact?.email || null,
    orgRole: grantee?.granteeContacts?.[0]?.contact?.title || null,
    annualBudget: grantee?.annual_budget !== undefined || null,
    fiscalYearStart: grantee?.fiscal_year_start || null,
    fiscalYearEnd: grantee?.fiscal_year_end || null,

    taxStatus: grantee?.tax_status_id || null,
    ...(grantee?.taxStatus?.id !==
      TaxStatusId['Federally Recognized Tribe'] && {
      tax_id: grantee?.tax_id || null
    }),
    checkRecipient: grantee?.check_recipient || null,

    checkCountry: grantee?.checkLocation?.country_alpha_2 || null,
    checkStreet: grantee?.checkLocation?.address1 || null,
    checkStateOrTerritory: grantee?.checkLocation?.state || null,
    checkCity: grantee?.checkLocation?.city || null,
    fund: Number.isInteger(grantee?.fund) || null
  };

  return Object.values(reqGranteeModel).includes(null);
};

// BIPOC UTILS

export const getBipocValue = (value?: BipocLed | boolean) => {
  if (value === true || value === BipocLed.YES) {
    return 'Yes';
  } else if (value === BipocLed.NOTSURE) {
    return 'Not Sure';
  } else {
    return '';
  }
};

export const getBipocType = (value: BipocLed | null) => {
  switch (value) {
    case BipocLed.YES:
      return 'Yes';
    case BipocLed.NO:
      return 'No';
    case BipocLed.NOTSURE:
      return 'Not Sure';
    default:
      return '';
  }
};

export const sortByBipocType = (items: number[]) => {
  return items.sort((a, b) => {
    const map: { [key: number]: number } = {};
    map[BipocLed.YES] = 1;
    map[BipocLed.NO] = 2;
    map[BipocLed.NOTSURE] = 3;

    if (map[a] < map[b]) {
      return -1;
    }

    if (map[a] > map[b]) {
      return 1;
    }

    return 0;
  });
};

export const getGranteePrimaryContact = (grantee: any, field: string) => {
  const contacts = grantee?.granteeContacts || [];
  // We are using always the first grantee contact as the primary due to this definition: https://justfund-internal.atlassian.net/browse/IM-274
  // const object = contacts.find((contact: any) => contact.primary) || contacts[0]
  const object = contacts[0];

  return object?.contact?.[field];
};

export const getPrimaryContactFromGrantee = (grantee: any) => {
  return {
    firstName: getGranteePrimaryContact(grantee, 'first_name'),
    lastName: getGranteePrimaryContact(grantee, 'last_name'),
    role: getGranteePrimaryContact(grantee, 'role'),
    title: getGranteePrimaryContact(grantee, 'title'),
    phone: getGranteePrimaryContact(grantee, 'phone'),
    email: getGranteePrimaryContact(grantee, 'email'),
    extension: getGranteePrimaryContact(grantee, 'extension')
  };
};

export const cleanObject = (obj: Object) => {
  return Object.entries(obj)
    .filter(
      ([_, v]) => v != null && v !== '' && !(Array.isArray(v) && v.length === 0)
    )
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
};

export const htmlToText = (html?: string) => {
  let span = document.createElement('span');
  span.innerHTML = html || '';
  return span.textContent || span.innerText;
};

export const getCheckInTypeText = (status: TStatus) => {
  return statusMap[status]?.text || '';
};

export const getCharacterCount = (text: string) => {
  return htmlToText(text).length;
};

export const isTaxStatusUpdated = (formData: any, rfp: IRfp) => {
  const updatedTaxStatus = formData.some((updatedStatus: any) => {
    const prevStatus = rfp.rfpTaxStatuses?.find(
      (status: any) => status.id === updatedStatus.id
    );
    const updatedDetail =
      updatedStatus.detail !== undefined &&
      prevStatus?.detail !== updatedStatus.detail;
    const addedTaxStatus = updatedStatus !== undefined && !updatedStatus.id;

    return updatedDetail || addedTaxStatus || updatedStatus.remove;
  });

  return updatedTaxStatus;
};

export const processHtmlWithAsterisk = (
  html: string,
  shouldAddAsterisk: boolean
): string => {
  if (!shouldAddAsterisk) return html;

  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  const findLastTextNode = (node: Node): Node | null => {
    if (node.nodeType === Node.TEXT_NODE && node.nodeValue?.trim()) return node;

    for (let i = node.childNodes.length - 1; i >= 0; i--) {
      const childNode = node.childNodes[i];
      const found = findLastTextNode(childNode);
      if (found) return found;
    }

    return null;
  };

  const lastTextNode = findLastTextNode(doc.body);
  if (lastTextNode) lastTextNode.nodeValue += ' *';

  return doc.body.innerHTML;
};

export const areSameDates = (dateA: string, dateB: string) => {
  return new Date(dateA).getTime() === new Date(dateB).getTime();
};

export const cleanIndentHTML = (html: string): string => {
  // Expresión regular para eliminar etiquetas repetidas de <li style="list-style-type: none;"> y <ul>
  // También elimina el atributo padding-left si está presente
  return html
    .replace(/<li style="list-style-type: none;">\s*<ul>/g, '') // Elimina <li><ul> repetidos
    .replace(/<\/ul>\s*<\/li>/g, '') // Elimina los cierres correspondientes </ul></li>
    .replace(/padding-left:\s*\d+px;?/g, '') // Elimina padding-left si está presente
    .replace(/style="[^"]*"/g, (match) => {
      // Limpia los estilos inline si quedaron vacíos
      return match.includes('list-style-type') || match.includes('padding-left')
        ? match.replace(/(list-style-type: none;?|padding-left: \d+px;?)/g, '').trim()
        : match;
    })
    .replace(/\sstyle=""/g, '') // Elimina atributos de estilo vacíos
    .trim(); // Elimina espacios extra en los bordes
};