import { AxiosError, AxiosRequestConfig } from 'axios';
import {
  ITokenResponse,
  IVerifyLoginResponse
} from '../../interfaces/IAuth.interfaces';
import { IUserAssociations } from '../../interfaces/IUserAssociations.interface';
import { api } from './api.service';

export interface IEntity {
  entityType: string | null;
  entityId: string | null;
}

type JFTokenParts = {
  header: {
    alg: string;
    type: string;
  };
  payload: {
    userId: number;
    iat: number;
    exp: number;
  };
  signature: string;
};

export class UserAuthService {
  private static baseRoute = '/api/users';
  private static storageKey = 'JF_TOKEN';
  private static entityTypeKey = 'JF_ENTITY_TYPE';
  private static entityIdKey = 'JF_ENTITY_ID';
  private static refreshTokenKey = 'JF_REFRESH_TOKEN';

  private static pendingRefresh: Promise<any> | null = null;

  private static set _token(token: string | null) {
    if (token) localStorage.setItem(this.storageKey, token);
    else localStorage.removeItem(this.storageKey);
  }

  // Token Helpers
  public static get token(): string | null {
    return localStorage.getItem(this.storageKey);
  }

  private static set refreshToken(token: string | null) {
    if (token) localStorage.setItem(this.refreshTokenKey, token);
    else localStorage.removeItem(this.refreshTokenKey);
  }

  private static get refreshToken(): string | null {
    return localStorage.getItem(this.refreshTokenKey);
  }

  private static get parsedToken(): JFTokenParts | null {
    if (this.token === null) return null;
    const [header, payload, signature] = this.token
      .split('.')
      .map((part, idx) => {
        return idx < 2 ? JSON.parse(atob(part)) : part;
      });

    return { header, payload, signature };
  }

  public static async login(
    email: string,
    password: string
  ): Promise<ITokenResponse> {
    const res = await api.post('/api/auth/login', { email, password });
    this._token = res.data?.token;
    this.refreshToken = res.data?.refreshToken;
    return res.data as ITokenResponse;
  }

  public static async exchangeToken(): Promise<string | null> {
    if (this.refreshToken === null)
      return new Promise((resolve) => resolve(null));
    if (this.pendingRefresh) return this.pendingRefresh;

    this.pendingRefresh = api
      .get('api/auth/refresh', {
        headers: { 'Refresh-Token': this.refreshToken }
      })
      .then((res) => {
        this.pendingRefresh = null;
        this._token = res.data.token;
        this.refreshToken = res.data.refreshToken;
      });
    return this.pendingRefresh;
  }

  public static async getAssociations(
    id: number,
    options: AxiosRequestConfig = {}
  ): Promise<IUserAssociations> {
    const res = await api.get(`${this.baseRoute}/${id}/associations`, options);
    return res.data as IUserAssociations;
  }

  /**
   * This function will confirm that the user is logged in and if they are not will log them out and
   * redirect to the login screen
   * @returns IVerifyLoginResponse
   */
  public static async verifyLogin(
    config: AxiosRequestConfig = {}
  ): Promise<IVerifyLoginResponse> {
    const maxRetries = 3;
    let attempts = 0;
  
    while (attempts < maxRetries) {
      try {
        const res = await api.get('/api/auth/verifylogin', config);
        return res.data as IVerifyLoginResponse;
      } catch (exc) {
        attempts++;
  
        const isAxiosError = exc as AxiosError;
        const status = isAxiosError ? (isAxiosError.response?.status || 0) : 0;
  
        if (status === 502 && attempts < maxRetries) {
          continue; // Reintenta si el código de estado es 502.
        }
  
        if (typeof exc === 'object' && exc !== null && isAxiosError && isAxiosError.message === 'canceled') {
          return Promise.reject(exc);
        }
  
        // Si no es un error 502 o se alcanzaron los intentos máximos, cierra sesión y lanza el error.
        this.logout(true);
        return Promise.reject(exc);
      }
    }
  
    // Si se agotan todos los intentos sin éxito, lanza un error.
    throw new Error('Failed to verify login after multiple attempts');
  }

  // Reset Password routes
  public static async sendPasswordReset(email: string): Promise<boolean> {
    const res = await api.post('/api/auth/reset-password-email', { email });
    return res.data as any;
  }

  public static async resetPassword(
    token: string,
    email: string,
    password: string
  ): Promise<boolean> {
    const res = await api.post(`/api/auth/reset-password/${token}`, {
      email,
      password
    });
    return res.data as any;
  }

  public static tokenIsExpired(): boolean {
    if (this.parsedToken === null) return true;
    const { payload } = this.parsedToken;
    const iatUnixMillis = parseInt(payload.iat + '000', 10);
    const fourHoursAfterCreation = new Date(iatUnixMillis).getTime() + 14400000;
    const expUnixMillis = parseInt(payload.exp + '000', 10);
    const earliestExpiration =
      fourHoursAfterCreation < expUnixMillis
        ? fourHoursAfterCreation
        : expUnixMillis;
    return new Date().getTime() > earliestExpiration;
  }

  // Used to support various roles a user can have... i.e. funder group, vs grantee
  public static getEntity(): IEntity {
    return {
      entityType: localStorage.getItem(this.entityTypeKey),
      entityId: localStorage.getItem(this.entityIdKey)
    };
  }

  public static setEntity({ entityType, entityId }: IEntity) {
    entityType
      ? localStorage.setItem(this.entityTypeKey, entityType)
      : localStorage.removeItem(this.entityTypeKey);
    entityId
      ? localStorage.setItem(this.entityIdKey, entityId)
      : localStorage.removeItem(this.entityIdKey);
  }

  public static logout(shouldRedirect = false) {
    if (this.tokenIsExpired()) {
      this.exchangeToken().then((newToken) => {
        if (!this.tokenIsExpired()) {
          return api.get('/api/auth/logout').then((response) => {
            this._token = null;
            this.refreshToken = null;
            this.setEntity({ entityId: null, entityType: null });
            window.location.replace(
              shouldRedirect
                ? `${
                    window.location.origin
                  }/justfund/signin?redirect=${encodeURIComponent(
                    window.location.pathname + window.location.search
                  )}`
                : `${window.location.origin}/justfund/signin`
            );
          });
        } else
          return window.location.replace(
            shouldRedirect
              ? `${
                  window.location.origin
                }/justfund/signin?redirect=${encodeURIComponent(
                  window.location.pathname + window.location.search
                )}`
              : `${window.location.origin}/justfund/signin`
          );
      });
    } else {
      api.get('/api/auth/logout').then((response) => {
        this._token = null;
        this.refreshToken = null;
        this.setEntity({ entityId: null, entityType: null });
        window.location.replace(
          shouldRedirect
            ? `${
                window.location.origin
              }/justfund/signin?redirect=${encodeURIComponent(
                window.location.pathname + window.location.search
              )}`
            : `${window.location.origin}/justfund/signin`
        );
      });
    }
  }

  public static async unSubscribeEmailFrequencyByToken(
    token: string
  ): Promise<void> {
    try {
      await api.post(`/api/auth/unsubscribe-email-frequency`, { token });
    } catch (error) {
      throw new Error('Failed to unsubscribe');
    }
  }
}
