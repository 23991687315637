import { TimeConstraints } from './types';

export const announcementTip =
  'JustFund requires that all announcement dates be scheduled Tuesdays - Fridays at 6:00 AM Pacific Standard Time to ensure that JustFund staff are available to provide assistance.';
export const deadlineTip =
  'JustFund requires that all cycle deadlines be scheduled Tuesdays - Thursdays at 5:00 PM Pacific Standard Time to ensure that JustFund staff are available to provide assistance';
export const decisionsTip =
  'Please indicate the date you anticipate notifying applicants of your decision.';
export const requiredErrorMessage = 'This field is required';

export enum KeyDates {
  ANNOUNCEMENT_DATE = 'announcementDate',
  DEADLINE_DATE = 'deadlineDate',
  DECISION_DATE = 'decisionDate',
  DISBURSEMENT_DATE = 'disbursementDate'
}

export const TIMEZONE = 'PST8PDT';

export const timeConstraints: TimeConstraints = {
  timeZone: TIMEZONE,
  announcementTime: [6, 0],
  deadlineTime: [17, 0]
};
