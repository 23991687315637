import { Link as RRLink, useLocation } from 'react-router-dom';
import { Box, Grid, Link, Typography } from '@mui/material';
import { Logo } from '../../common/logo/Logo';
import { SocialBar } from '../../social/SocialBar';

const classes  = {

  infoJustfundUs: {
    color: '#FFFFFF',
    fontSize: '18px',
    marginTop: '1em',
    textDecoration: 'underline',
    "&:hover": {
      textDecoration: 'underline',
    },
  },

  questionsComments: {
    color: '#FFFFFF',
    fontSize: '18px',
    marginBottom: '1.5em',
  },

  terms: {
    color: '#FFFFFF',
    fontFamily: 'Proxima Nova, sans-serif',
    fontSize: '12px',
    lineHeight: '0em',
    marginTop: '3em',
    textDecoration: 'underline',
    "&:hover": {
      textDecoration: 'underline',
    },
  },

  privacy: {
    color: '#FFFFFF',
    fontFamily: 'Proxima Nova, sans-serif',
    fontSize: '12px',
    lineHeight: '0em',
    marginTop: '3em',
    textDecoration: 'underline',
    "&:hover": {
      textDecoration: 'underline',
    },
  },

  boxStyleL1: {
    backgroundColor: '#091B30',
    borderTop: '1px solid #d8d8d8',
    MarginTop: '40px',
    width: '100%',
  },

  boxStyleL2: {
    padding: '0px 4px',
    maxWidth: '1024px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  boxStyleL3: {
    padding: '30px 4px 60px 4px',
    maxWidth: '1024px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  body2: {
    fontSize: '1.125rem',
    fontFamily: 'Proxima Nova, sans-serif',
    fontWeight: '400',
    lineHeight: '1.43',
    marginTop: '1em',
  },

  slash: {
    color: '#FFFFFF',
    fontFamily: 'Proxima Nova, sans-serif',
    fontSize: '12px',
    lineHeight: '0em',
    marginTop: '0.8em',
    textDecoration: 'none',
    "&:hover": {
      textDecoration: 'none',
    },
  },

};

export const Footer = () => {
  const location = useLocation();
  
  if (!(location.pathname === '/' || location.pathname.startsWith('/justfund'))) {
    return null;
  }

  return (
    <Box sx={classes.boxStyleL1}>
      <Box sx={{ ' @media (max-width: 768px)': { marginLeft: ' 1em' } }}>
        <Box pt='20px' fontWeight='bold' fontSize={12}>
          <Box sx={classes.boxStyleL2}>
            <Logo img='/images/JFU_logo.svg' />
            <SocialBar />
            <Typography sx={classes.body2} paragraph>
              <Link href='mailto:info@justfund.us' sx={classes.infoJustfundUs}>
                <strong>info@justfund.us</strong>
              </Link>
            </Typography>
          </Box>
          <Box sx={classes.boxStyleL3}>
            <Grid container spacing={2}>
              <Grid item>
                <Link variant='body1' component={RRLink} to='/justfund/terms' sx={classes.terms}>Terms</Link>
              </Grid>
              <Grid item>
                <Typography variant='body1' sx={classes.slash}>|</Typography>
              </Grid>
              <Grid item>
                <Link variant='body1' component={RRLink} to='/justfund/privacy' sx={classes.privacy}>Privacy</Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );

};
