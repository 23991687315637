import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { DateTime } from "luxon";

export const formatDate = (input = "", formatOption = "MM/dd/yyyy") => {
  if (!input) return input;
  return format(new Date(input), formatOption);
};

export const formatDateYMD = (input = "", formatOption = "yyyy/MM/dd") => {
  if (!input) return input;
  return format(new Date(input), formatOption);
};

export const fullName = ({ first_name, last_name }: any) => {
  return [first_name, last_name].filter((v) => !!v).join(" ");
};

export const formatString = (date: Date | number, formatOption = "MM/dd/yyyy") => format(date, formatOption);

export const formatUtcDate = (input = "", formatOption = "MM/dd/yyyy") => {
  if (!input) return input;
  return formatInTimeZone(new Date(input), 'utc', formatOption)
}


export const formatDatePSTAndEST = (date: string) => {
  const utcDate = DateTime.fromISO(date, { zone: 'utc' });
  const pstDate = utcDate.setZone('America/Los_Angeles');
  const estDate = utcDate.setZone('America/New_York');

  const formattedDate = pstDate.toFormat("MM/dd/yyyy");
  const formattedPST = pstDate.toFormat("h:mm a 'PST'");
  const formattedEST = estDate.toFormat("h:mm a 'EST'");

  return {
    formattedDate,
    formattedPST,
    formattedEST,

  }      
};