import React, { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { IRfp } from '../../interfaces/IRfp.interfaces';
import DOMPurify from 'dompurify';
import { format, utcToZonedTime } from 'date-fns-tz';
import { TIMEZONE } from '../../form-section/RfpKeyDates/constants';
import { formatDatePSTAndEST } from '../../util/formatters';

type props = {
  rfp: IRfp;
};

type KeyDateItemProps = {
  title: string;
  date?: Date;
  dateString?: string;
  isNewDate?: boolean;
  dateFormat?: string;
};

const classes = {
  fontSizeFix: {
    fontSize: 16
  }
};

const KeyDateItem: FC<KeyDateItemProps> = ({ date, dateString, title, isNewDate, dateFormat = 'MM/dd/yyyy hh:mm b, z' }) => (
  <Typography paragraph sx={classes.fontSizeFix}>
    <Box sx={{ fontWeight: 'bold', display: 'inline' }}>{`${title}: `}</Box>
    {date ? format(date, dateFormat, {timeZone: TIMEZONE}) : ''}
    {dateString ? dateString : ''}
    {isNewDate && (
      <Box sx={{ marginLeft: '10px', fontWeight: 'bold', display: 'inline' }}>
        NEW DATE
      </Box>
    )}
  </Typography>
);

export const KeyDates = ({ rfp }: props) => {
  if (rfp.rolling_cycle && !rfp.key_dates) {
    return (
      <Typography paragraph data-testid="rolling_info_text" sx={classes.fontSizeFix}>
        Applications will be accepted on a rolling basis
      </Typography>
    );
  }

  if (!rfp.key_dates) {
    return (
      <Box data-testid="dates_details_text">
        <KeyDateItem
          title={'Deadline to apply'}          
          dateString={rfp.deadline_date ? `${formatDatePSTAndEST(rfp.deadline_date).formattedDate} ${formatDatePSTAndEST(rfp.deadline_date).formattedPST} / ${formatDatePSTAndEST(rfp.deadline_date).formattedEST}`  : undefined}
          isNewDate={rfp.new_deadline_date}
        />
        <KeyDateItem
          title={'Decisions Expected to be Announced'}
          date={rfp.decision_date ? utcToZonedTime(new Date(rfp.decision_date), TIMEZONE) : undefined}
          isNewDate={rfp.new_decision_date}
          dateFormat={"MM/dd/yyyy"}
        />
      </Box>
    );
  }

  const keyDates = rfp?.key_dates ? DOMPurify.sanitize(rfp?.key_dates) : '';
  return (
    <Grid
      sx={classes.fontSizeFix}
      data-testid="key_dates_text"
      dangerouslySetInnerHTML={{ __html: keyDates }}
    />
  );
};
