import { useHistory } from 'react-router-dom';
export const Logo = (props) => {
  const history = useHistory ();
  
  return (
    <div style={props.style}>
      <img
        src={ props.img }
        height='36px'
        width='auto'
        alt='JustFund logo'
        style={{ cursor: 'pointer', marginRight: '2em', marginLeft: props.mlf }}
        onClick={() => { history.push (props.to); }}
      />
    </div>
  );

}
